import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import i18n from 'i18n-js';
import RangeSlider from '@App/components/search/advanced/RangeSlider';
import { setDuration as setDurationAction } from '@App/store/actions/search';
import { formatDuration } from '@App/utils';
import { split, join } from './utils';

const DURATION_OPTIONS = [
    { value: '0;180', label: '< 3:00' },
    { value: '180;300', label: '3:00 - 5:00' },
    { value: '300;450', label: '5:00 - 7:30' },
    { value: '450;720', label: '> 7:30' },
];

export default function DurationFilter({ minRange,maxRange }) {
    const storedDuration = useSelector(state => state.search.duration);
    
    const [selectedDuration, setSelectedDuration] = useState({
        min: storedDuration.min,
        max: storedDuration.max
    });

    const dispatch = useDispatch();
    const storeDuration = useCallback(
        // Send null max if selected max matches max range
        ({ min, max }) => dispatch(setDurationAction(min === minRange ? null : min, max === maxRange ? null : max)),
        [dispatch, minRange, maxRange],
    );


    // get updates from the store
    useEffect(() => {
        if ((storedDuration.max ?? maxRange) !== (selectedDuration.max ?? maxRange) || (storedDuration.min ?? minRange) !== (selectedDuration.min ?? minRange)) {
            setSelectedDuration(storedDuration);
        }
    // dont rerender on state update, else you'll have an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedDuration]);
    
    return (
        <div className="filters__inline">
            <span className="filters__label">
                {i18n.t('search.advanced_filters.duration.label')}
                <span>{formatDuration(selectedDuration.min)} - {formatDuration(selectedDuration.max)}</span>
            </span>

            <RangeSlider 
                value={selectedDuration}
                min={minRange}
                max={maxRange}
                onChange={setSelectedDuration}
                onChangeComplete={storeDuration}
            />

            <div className="filters__inline__radios">
                {DURATION_OPTIONS.map(({ label, value }) => (
                    <label 
                        key={value}
                        className={classNames('chip chip--filters', { 
                            'chip--active': join(selectedDuration) === value,
                        })}
                        style={{cursor: 'pointer'}}
                    >
                        <input 
                            type="radio"
                            name="duration"
                            value={value}
                            hidden
                            checked={join(selectedDuration) === value}
                            onChange={e => setSelectedDuration(split(e.target.value))}
                        />
                        {label}
                    </label>
                ))}
            </div>
        </div>
    );
}
